<template>
  <div v-if="loading">
    <component :is="spinnerName" />
  </div>
  <div v-else-if="journey">
    <router-view :journey="journey" />
  </div>
  <div v-else>
    {{ $t('error.retrieving_journey') }}
  </div>
</template>

<script type="text/javascript">
import { get as getJourney } from 'api/journeys'
import travelContents from 'mixins/travelContents'
import authentication from 'mixins/authentication'
import { MSpinnerGeo, MSpinnerArrow, MSpinnerKinto } from 'components/'

export default {
  name: 'Journeys',
  components: { MSpinnerGeo, MSpinnerArrow, MSpinnerKinto },
  mixins: [travelContents, authentication],
  data () {
    return {
      loading: true,
      journey: null
    }
  },
  computed: {
    spinnerName () {
      const spinnerType = this.$store.getters.partner.spinnerType
      return `m-spinner-${spinnerType}`
    }
  },
  watch: {
    '$route.params.reference' (val) {
      if (this.$route.name !== 'journey-timeline') return
      if (val === this.journey.reference) return
      this.getJourneyDetails()
    }
  },
  created () {
    this.getJourneyDetails()
  },
  methods: {
    async getJourneyDetails () {
      this.loading = true
      getJourney(this.$route.params.reference, { include: ['bookings'] })
        .then(res => {
          this.journey = res.data
        })
        .catch(err => {
          this.loading = false
          this.$q.notify({
            message: this.$t('error.default'),
            caption: err.data.message,
            type: 'negative'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
