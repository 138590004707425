<template>
  <component :is="component" />
</template>

<script type="text/javascript">
import { hasPermission } from 'utils/authentication'
import backOfficeJourneys from './backoffice-journeys'
import userJourneys from './user-journeys'
export default {
  name: 'Journeys',
  computed: {
    component () {
      if (hasPermission('can.book.for.anyone')) {
        return backOfficeJourneys
      } else {
        return userJourneys
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 1024px
</style>
