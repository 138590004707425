<template>
  <q-drawer
    ref="drawer"
    v-model="show"
    elevated
    side="right"
  >
    <q-list>
      <q-item-section>
        <q-item-label header>
          {{ $tc('filter', 2) }}
        </q-item-label>
      </q-item-section>
      <q-item v-if="$q.platform.is.desktop">
        <q-item-section>
          <q-field :label="$t('showing')" stack-label borderless>
            <q-option-group
              v-model="journeyFilter"
              :disable="disableFilters"
              :options="filterOptions"
            />
          </q-field>
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <m-date-field
            :label="$t('starts_after')"
            :value="dateFilter.start"
            @change="val => setDateRange(val, 'start')"
          />
          <m-date-field
            :label="$t('ends_before')"
            :value="dateFilter.end"
            @change="val => setDateRange(val, 'end')"
          />
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section class="type-buttons">
          <button
            v-for="item in contentTypes"
            :key="item"
            class="type-filter-button"
            :class="typeFilter.includes(item) ? 'selected' : null"
            @click="() => handleTypeFilter(item)"
          >
            <m-travel-icon class="m-icon" :type="item" color="white" :bg-color="getTravelContentHex(item)" size="36px" circle />
            {{ $tc(`content_type.${item}`) }}
          </button>
        </q-item-section>
      </q-item>
    </q-list>
  </q-drawer>
</template>
<script>
import { hasPermission } from 'utils/authentication'
import { MDateField, MTravelIcon } from 'components/'
import travelContents from 'mixins/travelContents'

export default {
  name: 'JourneysSidbear',
  components: { MDateField, MTravelIcon },
  mixins: [travelContents],
  data () {
    return {
      show: !this.$q.platform.is.mobile && !hasPermission('can.book.for.anyone'),
      filterOptions: [
        {
          label: this.$t('journey.upcoming'),
          value: 'upcoming'
        },
        {
          label: this.$t('journey.active'),
          value: 'now'
        },
        {
          label: this.$t('journey.previous'),
          value: 'past'
        }
      ]
    }
  },
  computed: {
    contentTypes () {
      return this.$store.getters.partner.bookables
        .filter(type => type !== 'onwardtravel')
        .map(type => this.getTravelPreferenceName(type))
    },
    disableFilters () {
      return this.$store.getters['entities/journeys/disableFilters']
    },
    journeyFilter: {
      get () {
        return this.$store.getters['entities/journeys/filter']
      },
      set (val) {
        this.$store.dispatch('entities/journeys/setFilter', val)
        if (this.$q.platform.is.mobile) this.$refs.drawer.hide()
      }
    },
    dateFilter: {
      get () {
        return this.$store.getters['entities/journeys/dateFilter']
      },
      set (val) {
        this.$store.dispatch('entities/journeys/setDateFilter', val)
      }
    },
    typeFilter () {
      return this.$store.getters['entities/journeys/typeFilter']
    }
  },
  methods: {
    setDateRange (date, type) {
      this.dateFilter[type] = date
    },
    handleTypeFilter (pref) {
      if (this.typeFilter.includes(pref)) this.$store.dispatch('entities/journeys/removeTypeFilter', pref)
      else this.$store.dispatch('entities/journeys/addTypeFilter', pref)
    }
  }
}
</script>

<style lang="stylus" scoped>
.type-buttons
  flex-direction row

.type-filter-button
  color var(--q-color-primary)
  border solid 1px var(--q-color-primary)
  background-color white
  display flex
  align-items center
  width 120px
  padding 5px
  margin 5px
  border-radius 15px
  &:active
    transform scale(0.95)
  &.selected
    background-color var(--q-color-selected)

.m-icon
  margin-right 5px
</style>
