<template>
  <div class="layout-padding q-pa-md column">
    <q-inner-loading :showing="!items">
      <q-spinner-dots size="60" />
    </q-inner-loading>
    <q-list separator>
      <place-card
        v-for="item in items"
        :key="item.place_id"
        v-bind="item"
        :icon="icon"
        @click.native="openURL(`https://www.google.com/maps/dir/?api=1&origin=${curLocation || item.vicinity}&destination=${item.name}&destination_place_id=${item.place_id}`)"
      />
    </q-list>
    <m-empty-state
      v-if="items && items.length == 0"
      :icon="`${content.icon}`"
    >
      {{ $t('journey.no_journey_found', { journey: content.text }) }}
    </m-empty-state>

    <m-discovery-detail-modal ref="detail" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
import http from 'api/http'
import { details } from 'api/places'
import travelContent from 'mixins/travelContents'
import placeCard from 'pages/discovery/placeCard.vue'
import { MEmptyState, MDiscoveryDetailModal } from 'components/'

export default {
  components: {
    placeCard,
    MEmptyState,
    MDiscoveryDetailModal
  },
  data () {
    return {
      items: null
    }
  },
  computed: {
    ...mapGetters({
      curLocation: 'discovery/location'
    }),
    type () {
      return this.$route.params.type
    },
    icon () {
      return this.$store.getters.partner.nearby.find(n => n.type === this.type).icon
    }
  },
  mounted () {
    this.get()
  },
  methods: {
    openURL,
    get () {
      http.get(`/journeys/${this.$route.params.reference}/discovery/${this.type}`)
        .then(response => {
          this.items = response.data
          this.content = travelContent.data().travelContents[this.type]
        })
    },
    show (item) {
      details(item.place_id)
        .then(response => {
          this.$refs.detail.showing = response.data
          this.$refs.detail.showing.rating = item.rating
          this.$refs.detail.$refs.modal.open()
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.content-around
  position relative
  .q-inner-loading
    background: transparent
    height: 200px
.layout-padding
  max-width 768px
.q-card
  height: 100%
  max-width 768px
  &-media > img.missing
    background-color #eee

.photo
  width: 64px
  height: 92px
  vertical-align: top
  background-size: cover
  background-position: center
</style>
