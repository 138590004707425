<template>
  <div class="journey-hotels" @click="$emit('hotel', hotel.reference)">
    <m-travel-icon class="hotel-icon" type="hotel" color="white" :bg-color="bgColor" size="32px" circle />
    <div class="hotel-name">
      {{ hotel.booking.name }}
    </div>
    <div v-if="price" class="hotel-price">
      {{ price }}
    </div>
    <div class="hotel-date">
      {{ hotel.date_span.start }} - {{ hotel.date_span.end }}
    </div>
  </div>
</template>

<script>
import { MTravelIcon } from 'components/'

export default {
  components: { MTravelIcon },
  props: [ 'hotel', 'price', 'bgColor' ]
}
</script>

<style lang="stylus" scoped>
.journey-hotels
  display grid
  grid-template-columns 60px auto 100px
  grid-template-rows auto auto
  padding 5px 0
  .hotel-icon
    grid-row span 2
    align-self center
    margin 5px
  .hotel-date
    grid-column span 2
  .hotel-name, .hotel-price
    font-weight 600

.hotel-price
  justify-self flex-end
</style>
