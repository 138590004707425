<template>
  <q-card v-if="journeyTypes.length" class="journey-card">
    <div class="journey-name">
      {{ name }}
    </div>
    <div class="journey-price">
      {{ total_price.text }}
    </div>
    <div class="journey-icons">
      <m-travel-icon
        v-for="type in journeyTypes"
        :key="type"
        :type="type"
        class="journey-icon"
        color="white"
        :bg-color="getTravelContentHex(type)"
        size="32px"
        circle
      />
    </div>
    <div class="journey-date">
      <span class="primary">From:</span> {{ startDate | format }}
    </div>
    <div class="journey-time">
      {{ startDate | time }}
    </div>
    <div class="journey-date">
      <span class="primary">Until:</span> {{ endDate | format }}
    </div>
    <div class="journey-time">
      {{ endDate | time }}
    </div>
    <hotel-card v-for="(hotel, i) in hotels" :key="`${hotel}-${i}`" :hotel="hotel" :bg-color="getTravelContentHex('hotel')" />
  </q-card>
  <q-card v-else-if="hotels.length">
    <hotel-card :hotel="hotels[0]" :price="total_price.text" />
  </q-card>
</template>

<script type="text/javascript">
import date from 'utils/date-time'
import { MTravelIcon } from 'components/'
import hotelCard from './hotel-card.vue'
import travelContents from 'mixins/travelContents'
const { toMediumShortDayDate, toCivilTime } = date

export default {
  components: { hotelCard, MTravelIcon },
  filters: {
    format (value) {
      if (!value) return
      return toMediumShortDayDate(value)
    },
    time (value) {
      if (!value) return
      return toCivilTime(value)
    }
  },
  mixins: [travelContents],
  props: {
    journey: {
      type: Object
    },
    name: {
      type: String,
      default: 'Leeds journey'
    },
    // eslint-disable-next-line
    total_price: {
      type: Object
    },
    items: {
      type: Object
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    journeyTypes () {
      return this.items.data
        // Remove "start"/"end" journey points
        .filter(item => item.booking.type && item.booking.type !== 'hotel')
        // Map to an array of type strings
        .map(item => item.booking.type)
        // Reduce to only one of each type
        .reduce((typeArr, type) => {
          if (!typeArr.includes(type)) typeArr.push(type)
          return typeArr
        }, [])
    },
    hotels () {
      const shapedHotels = []
      const hotels = this.items.data.filter(item => item.booking.type === 'hotel')
      hotels.forEach((hotel, index) => {
        /* This logic is backwards, but works smoother than the logical order */
        // If it's odd-numbered (check-out time), add it to the list
        if (index % 2) shapedHotels.push(hotel)
        // Otherwise, add the check-in time to the check-out object
        else hotels[index + 1].starts_at = hotel.starts_at
      })
      shapedHotels.forEach(hotel => {
        const startDate = date.newDate(hotel.starts_at.date)
        const endDate = date.newDate(hotel.ends_at.date)
        const date_span = {
          start: '',
          end: date.toMediumShortDate(endDate)
        }
        date_span.start += startDate.day
        if (startDate.month !== endDate.month) date_span.start += ' ' + startDate.monthShort
        if (startDate.year !== endDate.year) date_span.start += ' ' + startDate.year

        hotel.date_span = date_span
      })
      return shapedHotels
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  height fit-content
  margin 0px
  border-radius 0
  padding 10px 20px
  font-weight 400
  font-size 95%
  color black
  &.journey-card
    display grid
    grid-template-columns auto 75px
    grid-row-gap 5px

.journey-name, .journey-price
  font-weight 550
  font-size initial
  text-overflow ellipsis
  overflow hidden
  white-space nowrap

.journey-icons, .journey-hotels
  grid-column span 2

.journey-icons
  display flex

.journey-time, .journey-price
  justify-self flex-end

.journey-time
  color var(--q-color-primary)
  font-weight 550

.journey-date
  span
    font-weight 550
    font-size initial

.journey-icon
  margin 5px
</style>
