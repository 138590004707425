<template>
  <div class="row items-center justify-center border-0 bottom55 bg-white">
    <div class="timeline-header">
      <h5>{{ journey.name }}</h5>
    </div>
    <div class="col-12 col-md-8">
      <m-booking-timeline :entries="entriesByDay" />
      <h6 v-if="hotels.length">
        {{ $tc('content_type.hotel', 2) }}
      </h6>
      <hotel-card
        v-for="hotel in hotels"
        :key="hotel.reference"
        :bg-color="getTravelContentHex('hotel')"
        :hotel="hotel"
        :price="hotel.price"
        class="hotel-card"
        @hotel="handleClick"
      />
    </div>
    <hr>
    <div class="total-cost">
      {{ $t('total_cost') }} {{ journey.total_price.text }}
    </div>
    <div class="timeline-footer">
      <q-icon name="fas fa-hand-point-up" size="30px" />
      {{ $t('journey_timeline_footer') }}
    </div>
  </div>
</template>

<script type="text/javascript">
import { MBookingTimeline } from 'components'
import authentication from 'mixins/authentication'
import travelContents from 'mixins/travelContents'
import date from 'utils/date-time'
import hotelCard from '../../user-journeys/hotel-card'
const { toCivilDate, toMediumDate, getDateDiff } = date

export default {
  name: 'JourneysTimeline',
  components: { hotelCard, MBookingTimeline },
  mixins: [authentication, travelContents],
  props: ['journey'],
  computed: {
    days () {
      const filteredDays = []

      this.journey.items.data
        // Take out start/end points and hotels
        .filter(item => item.booking.type && item.booking.type !== 'hotel')
        // Flatten each item into its start date
        .map(item => toCivilDate(item.starts_at.date))
        // Sort into date order
        .sort()
        // Filter out duplicates
        .forEach(day => {
          if (!filteredDays.includes(day)) filteredDays.push(day)
        })

      return filteredDays
    },
    hotels () {
      const shapedHotels = []
      this.journey.bookings.data
        .filter(item => item.type === 'hotel')
        .forEach(hotel => {
          const startDate = date.newDate(hotel.attributes.check_in)
          const endDate = date.newDate(hotel.attributes.check_out)
          const date_span = {
            start: '',
            end: date.toMediumShortDate(endDate)
          }
          date_span.start += startDate.day
          if (startDate.month !== endDate.month) date_span.start += ' ' + startDate.monthShort
          if (startDate.year !== endDate.year) date_span.start += ' ' + startDate.year
          shapedHotels.push({
            booking: { name: hotel.attributes.name },
            price: hotel.price.total.text,
            date_span,
            reference: hotel.reference
          })
        })
      return shapedHotels
    },
    bookings () {
      const shapedBookings = []

      this.journey.bookings.data.forEach(booking => {
        // Remove cancelled bookings
        if (booking.cancelled_at) return
        // Create a separate entry for the return leg
        if (booking.attributes.return_legs?.length) {
          const returnBooking = {
            ...booking,
            price: null,
            reference: booking.reference + '-return',
            starts_at: {
              date: booking.attributes.return_legs[0].depart_at
            },
            attributes: {
              outbound_legs: booking.attributes.return_legs
            }
          }
          shapedBookings.push(returnBooking)
        }
        // Push the original entry either way
        shapedBookings.push(booking)
      })

      // Then sort the entries by start time
      const sortedBookings = shapedBookings.sort((a, b) => {
        return getDateDiff(a.starts_at.date, b.starts_at.date)
      })

      return sortedBookings
    },
    entriesByDay () {
      const entriesByDay = []
      this.days.forEach(day => {
        const entryDay = { day, bookings: [] }
        this.bookings.forEach(booking => {
          if (booking.type === 'hotel') return
          if (toCivilDate(booking.starts_at.date) === day) {
            entryDay.bookings.push(booking)
          }
        })
        entriesByDay.push(entryDay)
      })
      return entriesByDay
    }
  },
  methods: {
    toCivilDate,
    toMediumDate,
    handleClick (reference) {
      if (this.hasPermission('can.book.for.anyone')) {
        this.$router.push({ hash: `/booking`, query: { booking: reference } })
      } else {
        this.$router.push(`/bookings/${reference}`)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
div
  color black

.timeline-header
  position sticky
  width 100vw
  top 0
  background white
  height 50px
  display flex
  align-items: center
  padding 0 10px
  box-shadow 0 3px 5px 1px #aaa
  z-index 2
  margin-bottom 5px
  h5
    text-overflow ellipsis
    white-space nowrap
    overflow hidden

.timeline-footer
  position sticky
  width 50vw
  bottom 15px
  background var(--q-color-primary)
  color white
  height fit-content
  display grid
  grid-template-columns 32px auto
  grid-column-gap 10px
  align-items center
  justify-items center
  box-shadow 0 -1px 3px 1px #aaa
  padding 5px
  font-size 90%
  z-index 2
  margin-top 5px
  @media (max-width 768px)
    width 100vw

.total-cost
  display flex
  align-items flex-end
  justify-content flex-end
  padding 5px 10px
  width 100vw
  font-size 110%
  @media (min-width 768px)
    max-width 50vw

h6
  padding-left 15px

hr
  width 98vw
  border 1px solid #ddd

.bottom55
  position relative
  padding-bottom 15px

em
  color var(--q-color-primary)

.hotel-card
  margin 0 30px 0 25px
  font-size 90%
</style>
